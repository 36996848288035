import { Link } from 'gatsby';
import React, { ReactNode } from 'react';
import BaseComponent from '../../base/base-component';
import './404.sass';

export default class NotFoundPage extends BaseComponent {
  public render(): ReactNode {
    return (
      <main className="page-styles">
        <title>Not found</title>
        <h1 className="heading-styles">Page not found</h1>
        <p className="paragraph-styles" data-id="share">
          Sorry{' '}
          <span role="img" aria-label="Pensive emoji">
            😔
          </span>{' '}
          we couldn’t find what you were looking for.
          <br />
          <Link to="/">Go home</Link>.
        </p>
      </main>
    );
  }
}
